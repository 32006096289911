import _browserProcessHrtime from "browser-process-hrtime";
var exports = {};
// Browserify's process implementation doesn't have hrtime, and this package is small so not much of a burden for
// Node.js users.
const hrtime = _browserProcessHrtime;

function toMS([sec, nanosec]) {
  return sec * 1000 + nanosec / 1000000;
}

exports = {
  hrtime,
  toMS
};
export default exports;