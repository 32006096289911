import _globalMonotonicClock from "./lib/global-monotonic-clock";
import _performance from "./lib/performance";
import _clockIsAccurate from "./lib/clock-is-accurate";
var exports = {};
const {
  getGlobalMonotonicClockMS
} = _globalMonotonicClock;
const {
  Performance
} = _performance;
const clockIsAccurate = _clockIsAccurate;
exports = {
  Performance,
  getGlobalMonotonicClockMS,
  clockIsAccurate
};
export default exports;
const _Performance = exports.Performance,
      _getGlobalMonotonicClockMS = exports.getGlobalMonotonicClockMS,
      _clockIsAccurate2 = exports.clockIsAccurate;
export { _Performance as Performance, _getGlobalMonotonicClockMS as getGlobalMonotonicClockMS, _clockIsAccurate2 as clockIsAccurate };