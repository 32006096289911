import _utils from "./utils";
var exports = {};
const {
  hrtime,
  toMS
} = _utils; // Returns the DOMHighResTimeStamp representing the high resolution time value of the global monotonic clock.

function getGlobalMonotonicClockMS() {
  return toMS(hrtime());
}

exports = {
  getGlobalMonotonicClockMS
};
export default exports;